/** @jsx jsx */
import {jsx} from '@emotion/react'
import styled from '@emotion/styled';
import React from 'react';
import {CSSInterpolation} from '@emotion/serialize';

type ColumnSizings = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type RelativeSizings = 'xs' | 'sm' | 'md' | 'lg';

const scale = 1.25;

const baseVertical = 16;
const spacingVertical: Map<RelativeSizings, number> = new Map([
    ['xs', baseVertical],
    ['sm', baseVertical * scale],
    ['md', baseVertical * scale * scale],
    ['lg', baseVertical * scale * scale * scale],
]);

const baseHorizontal = 16;
const spacingHorizontal: Map<RelativeSizings, number> = new Map([
    ['xs', baseHorizontal],
    ['sm', baseHorizontal * scale],
    ['md', baseHorizontal * scale * scale],
    ['lg', baseHorizontal * scale * scale * scale],
]);

const breakpoints: Map<RelativeSizings, string> = new Map([
    ['xs', '@media (min-width: 576px)'],
    ['sm', '@media (min-width: 768px)'],
    ['md', '@media (min-width: 992px)'],
    ['lg', '@media (min-width: 1200px)'],
]);

export interface FlexColProps {
    default?: ColumnSizings,
    xs?: ColumnSizings,
    sm?: ColumnSizings,
    md?: ColumnSizings,
    lg?: ColumnSizings,
}

const sizing = (size: ColumnSizings) => {
    const spacing = `${(100 / 12) * size}%`;

    return {
        flexBasis: spacing,
        flexGrow: 0,
        maxWidth: spacing,
    };
}

const FlexCol = styled.div<FlexColProps>((props) => {

    let styles: CSSInterpolation = {};

    if (props.default !== undefined) {
        styles = sizing(props.default);
    }

    let size = breakpoints.get('xs');
    if (props.xs !== undefined && size !== undefined) {
        styles[size] = sizing(props.xs)
    }

    size = breakpoints.get('sm');
    if (props.sm !== undefined && size !== undefined) {
        styles[size] = sizing(props.sm)
    }

    size = breakpoints.get('md');
    if (props.md !== undefined && size !== undefined) {
        styles[size] = sizing(props.md)
    }

    size = breakpoints.get('lg');
    if (props.lg !== undefined && size !== undefined) {
        styles[size] = sizing(props.lg)
    }

    return styles;
});

export interface FlexProps {
    spacing?: RelativeSizings,
    justify?: 'start' | 'center' | 'end' | 'around' | 'between',
}
export interface FlexFunctional extends React.FC<FlexProps> {
    Column: typeof FlexCol,
}

export const Flex: FlexFunctional = (props) => {
    const styles: Array<CSSInterpolation> = [{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'row wrap',
        '&>*': {
            boxSizing: 'border-box',
            flex: '1 1 auto',
        }
    }];

    if (props.justify) {
        styles.push({
           justifyContent: props.justify,
        });
    }

    if (props.spacing) {
        const v: number = spacingVertical.get(props.spacing) || 0;
        const h: number = spacingHorizontal.get(props.spacing) || 0;

        styles.push([{
            marginTop: `-${v}px`,
            marginLeft: `-${h}px`,
            '&>*': {
                paddingTop: `${v}px`,
                paddingLeft: `${h}px`,
            }
        }]);
    }

    return <div css={styles}>{props.children}</div>
}

Flex.Column = FlexCol;
