import styled from '@emotion/styled';

export interface BoxProps {
    isVertical?: boolean,
}

export const Box = styled.div<BoxProps>((props) => ({
    display: 'flex',
    flexDirection: props.isVertical ? 'column' : 'row',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch',

    '&>*': {
        flex: '0 1 auto',
    },
    '&>.key': {
        flexGrow: 1,
    }
}));
