import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyC6zB7oDMEe01z52GBYIIJ--bFX6DkUzYU",
    authDomain: "keystone-crusade-19c8c.firebaseapp.com",
    projectId: "keystone-crusade-19c8c",
    storageBucket: "keystone-crusade-19c8c.appspot.com",
    messagingSenderId: "41390146588",
    appId: "1:41390146588:web:fa56a45c35dc3217188bd9",
    measurementId: "G-W0WMH8WJD5"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
