import {isText} from './isText';
import {FieldType, IsValid} from '../interfaces';
import {isEmail} from './isEmail';
import {isPasswordSafe} from './isPasswordSafe';

const defaultIsValid: IsValid = (value) => {
    return (value !== '')
        ? { ok: true, message: ''}
        : { ok: false, message: 'A value must be passed'}
}

export const getValidator = (type: FieldType): IsValid => {
    switch (type) {
        case 'text':
            return isText;
        case 'email':
            return isEmail;
        case 'password':
            return isPasswordSafe;
        default:
            return defaultIsValid;
    }
}
