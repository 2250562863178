import React from 'react';
import {Providers} from './Providers';
import Login from './screen/Login';
import Chroma from './Chroma';

export const App = () => {
    return (
        <Providers>
            <Login><Chroma /></Login>
        </Providers>
    );
};
