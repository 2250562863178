import React from "react";
import firebase from "firebase/auth";
import { Context } from "./Context";
import { auth } from "./firebaseSetup";

export const AuthProvider: React.FC = (props) => {
    const [user, setUser] = React.useState<firebase.User | null>(null);

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            setUser(firebaseUser);
        });

        return unsubscribe;
    }, []);

    return <Context.Provider value={user}>{props.children}</Context.Provider>;
};
