import {CustomTheme} from '../interfaces';

export const palette: CustomTheme['palette'] = {
    primary: {
        main: '#18181d',
        light: '#74748b',
        dark: '#000000',
    },
    secondary: {
        main: '#435c50',
        light: '#435c50',
        dark: '#435c50',
    },
    warning: {
        main: '#db4b68',
        light: '#db4b68',
        dark: '#db4b68',
    }
}
