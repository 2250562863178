import React from "react";
import {Helmet} from "react-helmet";
import {Global as Erg, css} from '@emotion/react';

export const Global = () => {
    const common = css({
        '*': {
            fontFamily: "'Montserrat', sans-serif",
        },
        'body': {
            margin: 0,
        },
    });

    return (
        <>
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
                <link href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap" rel="stylesheet" />
            </Helmet>
            <Erg styles={common} />
        </>
    );
}

export default Global;
