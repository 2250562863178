/** @jsx jsx */
import {jsx} from '@emotion/react';
import {CSSInterpolation} from '@emotion/serialize';
import {useTheme, CustomTheme, Sizing} from '@vogue/theme';

import React, {useState} from 'react';
import {getValidator} from './validators/getValidator';
import {Callback, FieldType, IsValid} from './interfaces';

export interface FieldProps {
    label: string,
    type: FieldType,

    initialValue?: string,
    isValid?: IsValid,
    padding?: Sizing,
    variant?: keyof CustomTheme['palette'],

    callback?: Callback,
}

const getStyles = (theme: CustomTheme, props: FieldProps): CSSInterpolation => {
    return {
        input: {
            width: '100%',
            backgroundColor: '#f5f5f5',
            border: 'none',
            borderBottom: 'solid 2px #ccc',
            boxSizing: 'border-box',
            outline: 'none',
            padding: theme.spacing(props.padding || 'md'),
        },
        marginBottom: theme.spacing(props.padding || 'md', 'vertical'),
        div: {
            maxHeight: 0,
            transition: 'max-height 0.25s ease-out',
            overflow: 'hidden',
        }
    };
}

const getErrorStyles = (theme: CustomTheme): CSSInterpolation => {
    return {
        input: {
            borderBottomColor: theme.palette.warning.light,
        },
        div: {
            color: theme.palette.warning.main,
            maxHeight: '500px',
            transition: 'max-height 0.25s ease-in',
        }
    };
}

const fakeCallback: Callback = () => {

}

export const Field: React.FC<FieldProps> = (props) => {

    const theme = useTheme();
    const styles: Array<CSSInterpolation> = [getStyles(theme, props)];
    const errorStyle = getErrorStyles(theme);
    const validator: IsValid = props.isValid || getValidator(props.type);
    const passback: Callback = props.callback || fakeCallback;

    const [error, setError] = useState<string>('');
    const [value, setValue] = useState<string>(props.initialValue || '');
    const [hasBlurredBefore, setHasBlurredBefore] = useState<boolean>(false);

    const checkValue = (cv: string) => {
        const {ok, message} = validator(cv);
        setError(ok ? '' : message);
        passback(ok ? cv : '');
    }

    const onBlur = () => {
        setHasBlurredBefore(true);
        checkValue(value);
    }
    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;
        setValue(newValue);

        if (hasBlurredBefore) {
            checkValue(newValue);
        }
    }

    if (error !== '') {
        styles.push(errorStyle);
    }

    return (
        <div css={styles}>
            <input
                type={props.type}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
            />
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{__html: error}} />
        </div>
    );
};

export default Field;
