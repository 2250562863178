import React from 'react';
import {Global, VogueThemeProvider} from '@vogue/theme/src';
import {BrowserRouter} from 'react-router-dom';
import {AuthProvider} from './auth/AuthProvider';

export const Providers: React.FC = (props) => {
    return (
        <VogueThemeProvider>
            <Global />
            <BrowserRouter>
                <AuthProvider>
                    {props.children}
                </AuthProvider>
            </BrowserRouter>
        </VogueThemeProvider>
    );
};
