import React from 'react';
import {Link, Route, Routes} from 'react-router-dom';
import {Header, Spinner} from '@vogue/display';

const Settlement = React.lazy(() => import('./screen/Settlement'));
const Map = React.lazy(() => import('./screen/Map'));

const Chroma: React.FC = () => {
    return (
        <>
            <Header>
                <Header.Brand>Keystone Crusade</Header.Brand>
                <div>
                    <Link to="/">Settlement</Link>
                    <Link to="/map">Map</Link>
                </div>
            </Header>
            <React.Suspense fallback={<Spinner/>}>
                <Routes>
                    <Route path="/" element={<Settlement/>}/>
                    <Route path="/map" element={<Map/>}/>
                </Routes>
            </React.Suspense>
        </>
    );
};

export default Chroma;
