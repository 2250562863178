import React, {useState} from 'react';
import {Flex} from '@vogue/layout';
import {Field, Button} from '@vogue/inputs';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import {auth} from '../auth/firebaseSetup';
import useAuth from '../auth/useAuth';

const Login: React.FC = (props) => {

    const user = useAuth();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const isValid = () => {
        return email !== '' && password !== '';
    }

    const createAccount = async () => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
        } catch (error) {
            // do nothing for now
        }
    };

    const signIn = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            // do nothing for now
        }
    };

    /**
    const signOut = async () => {
        await auth.signOut();
    };
     */

    return (
        <>
            {user === null ? (
                <Flex spacing="md" justify="center">
                    <Flex.Column default={12} xs={9} sm={6}>
                        <Field label="Email" type="email" callback={setEmail} />
                        <Field label="Password" type="password" callback={setPassword} />
                        <Flex spacing="md">
                            <Flex.Column default={6}>
                                <Button disabled={!isValid()} onClick={signIn}>Login</Button>
                            </Flex.Column>
                            <Flex.Column default={6}>
                                <Button disabled={!isValid()} onClick={createAccount}>Signup</Button>
                            </Flex.Column>
                        </Flex>
                    </Flex.Column>
                </Flex>
            ) : (<>{props.children}</>)}
        </>
    );
};

export default Login;
