/** @jsx jsx */
import {jsx} from '@emotion/react';
import React, {MouseEventHandler} from 'react';
import {CSSInterpolation} from '@emotion/serialize';

import {CustomTheme, useTheme, Sizing} from '@vogue/theme';

export interface ButtonProps {
    variant?: keyof CustomTheme['palette'],
    sizing?: Sizing,
    disabled?: boolean,
    onClick?: MouseEventHandler<HTMLButtonElement>,
}

const getStyle = (theme: CustomTheme, props: ButtonProps): CSSInterpolation => {
    return {
        display: 'block',
        backgroundColor: theme.palette[props.variant || 'primary'].main,
        padding: theme.spacing(props.sizing || 'md'),
        color: 'white',
        border: 'none',
        width: '100%',
        fontWeight: 700,
        '&:disabled': {
            backgroundColor: theme.palette[props.variant || 'primary'].light,
            color: '#ccc',
        }
    }
}

export const Button: React.FC<ButtonProps> = (props) => {
    const theme = useTheme();

    return (
        <button
            type="button"
            css={getStyle(theme, props)}
            disabled={props.disabled}
            onClick={props.onClick}
        >{props.children}</button>
    );
}
